import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const ProductCardLoader = (props: IContentLoaderProps) => (
    <ContentLoader
        speed={1}
        width="100%"
        height="100%"
        viewBox="0 0 350 600"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
        {...props}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="342" />
        <rect x="76" y="400" rx="0" ry="0" width="198" height="10" />
        <rect x="42" y="425" rx="0" ry="0" width="266" height="10" />
        <rect x="76" y="450" rx="0" ry="0" width="198" height="10" />
        <rect x="29" y="525" rx="6" ry="6" width="292" height="48" />
    </ContentLoader>
);

export default ProductCardLoader;
