import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { SwiperOptions } from 'swiper';
import ChevronLeft from '$icons/chevron-left.svg';
import ChevronRight from '$icons/chevron.svg';
import { Gutter } from '~/shared/components';
import Button, { StyledButton } from '~/shared/components/Button';
import { Carousel } from '~/shared/components/Carousel';
import carouselStyles from '~/shared/components/Carousel/Carousel.module.scss';
import Flex from '~/shared/components/Flex';
import FlexContent from '~/shared/components/FlexGrowContent';
import { Headline, Link } from '~/lib';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { ModuleHeader } from '~/templates/blocks/components/ModuleHeader';
import { Description, Label, Name, Wrapper } from '../ProductCard';
import { CTA } from '../ProductCard/components/CTA';
import { Notifications } from '../ProductCard/components/Notifications';
import { Price, PriceLabel } from '../ProductCard/components/Price';
import { breakpoints } from '~/theme/breakpoints';
import { Hover } from '../ProductCard/components/ProductCardLink';

type CardCarouselProps = {
    /**
     * Carousel headline
     */
    headline?: Headline;

    subHeadline?: Headline;

    /**
     * List of products to be shown in carousel
     */
    callToAction?: Link;
    options?: SwiperOptions;
    backgroundColor?: string;
    items?: React.ReactNode[];
    className?: string;
};

export const CardCarousel = styled(
    ({
        headline,
        items,
        subHeadline,
        callToAction,
        options,
        className,
        ...rest
    }: CardCarouselProps) => {
        const leftArrow = useRef<HTMLButtonElement>(null);
        const rightArrow = useRef<HTMLButtonElement>(null);
        return (
            <ModuleContainer
                {...rest}
                className={className}
                spacingBottom="small"
                spacingTop="small"
            >
                <Gutter gutterSize="medium" gutterOnMobile>
                    <Flex alignItems="start">
                        <StyledCarouselButton aria-label="Previous" variant="icon" ref={leftArrow}>
                            <ChevronLeft />
                        </StyledCarouselButton>
                        <FlexContent grow={1}>
                            <ModuleHeader
                                headline={headline}
                                subHeadline={subHeadline}
                                link={callToAction}
                            />
                        </FlexContent>
                        <StyledCarouselButton aria-label="Next" variant="icon" ref={rightArrow}>
                            <ChevronRight />
                        </StyledCarouselButton>
                    </Flex>
                </Gutter>

                <Gutter gutterSize="medium" gutterOnMobile>
                    <Carousel
                        options={options}
                        prevRef={leftArrow}
                        nextRef={rightArrow}
                        items={items}
                    ></Carousel>
                </Gutter>
            </ModuleContainer>
        );
    },
)(({ theme }) => ({
    overflow: 'hidden',
    '.swiper': {
        overflow: 'visible',
    },
    [`.${carouselStyles.carouselContainer}`]: {
        marginTop: theme.space[6],
        overflow: 'visible',
    },
    [`${StyledButton}`]: {
        '&:disabled': {
            background: 'transparent',
            color: theme.colors.disabled,
        },
    },
    [`${Name}`]: {
        fontSize: theme.fontSizes['sm'],
    },
    [`${Label}`]: {
        fontSize: '13px',
    },
    [`${Description}`]: {
        fontSize: '13px',
    },
    [`${CTA}`]: {
        maxHeight: '40px',
        fontSize: '12px',
    },
    [`${Notifications}`]: {
        fontSize: '13px',
    },
    [`${Price}`]: {
        fontSize: theme.fontSizes['sm'],
    },
    [`${PriceLabel}`]: {
        fontSize: '13px',
    },
    [`${Wrapper}`]: {
        width: '270px',
    },
    [`${Hover}`]: {
        border: `1px solid ${theme.colors.brownLight}`,
    },

    [breakpoints.md]: {
        [`${Name}`]: {
            lineHeight: theme.lineHeights.single,
        },
    },
}));

export const StyledCarouselButton = styled(Button)({
    paddingRight: 0,
    paddingLeft: 0,
    svg: {
        height: '16px',
        width: '16px',
    },
    ':hover': {
        backgroundColor: 'transparent',
    },
});
